<template>
  <div class="addForm">
        <router-link to="/user/MSch">
        <span @click="backSch">校园公告</span>
        </router-link>
        <em>/</em>
        <span>发布公告</span>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
        </el-form-item>

        <!-- <el-form  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="10px"> -->

   <!-- 富文本编辑器 -->
        <el-form-item label="内容"  :model="ruleForm" :rules="rules" ref="ruleForm"  prop="articleBody">
          <!-- 失去焦点时手动校验该字段 (当前 articleBody 属性值) -->
            <quill-editor

                v-model="form.content"/>
      </el-form-item>

    <!-- </el-form> -->
        <el-form-item class="addBtn">
          <el-button type="primary" @click="saveQueBtn">下架存草稿</el-button>
            <el-button type="primary" @click="addQueBtn">发布</el-button>
          <router-link to="/user/userQuestion">

          <el-button @click="backSch">取消</el-button>
          </router-link>
        </el-form-item>
        </el-form>

  </div>
</template>

<script>
import { addSchArt, getSchArt } from '../../../API/schArt.js'
// import richBox from '../../richText.vue'
export default {
  name: 'addSchart',
  data () {
    return {
      userName: '123',
      form: {
        isdown:0,
        title: '',
        content: ''
      },
      ruleForm: {
        // ...省略其他
        articleBody: ''
      },
      rules: {
        // ..省略其他
        articleBody: [
          { required: true, message: '请输入文本', trigger: 'change' }
        ]
      }

    }
  },
  // components: {
  //   richBox: richBox
  // },
  methods: {
    async  addQueBtn () {
      if (this.form.title.trim() !== '' || this.form.content.trim() !== '') {
        //   内容不为空时，发起请求
        this.form.isdown=0
        const { data: res } = await addSchArt(this.form)
        if (res.status === 0) {
          this.$message({
            showClose: true,
            type: 'success',
            message: res.message
          })
          this.$emit('showSchInfo', true)
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.message
          })
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '标题或内容不能为空'
        })
      }
    },
    async  saveQueBtn () {
      if (this.form.title.trim() !== '' || this.form.content.trim() !== '') {
        //   内容不为空时，发起请求
        this.form.isdown=1
        const { data: res } = await addSchArt(this.form)
        if (res.status === 0) {
          this.$message({
            showClose: true,
            type: 'success',
            message: res.message
          })
          // this.$emit('showQueInfo', true)
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.message
          })
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '标题或内容不能为空'
        })
      }
    },
    backSch () {
      this.$emit('showSchInfo', true)
    }
  },
  async getQuestion () {
    const { data: res } = await getSchArt()
    // console.log(res)
  }

}
</script>

<style lang="less" scoped>
// *{
//     margin: 0;
//     padding: 0;
// }
 .addForm{
    background-color: #fff;
    .el-form {
        margin: 10px auto;
        width: 80%;
       /deep/  .ql-editor{
            min-height: 300px;
        }
        .addBtn {

        padding: 20px 0;
        display: flex;
        justify-content: center;
         /deep/ .el-form-item__content {
            margin: 0 !important ;
            button{
                margin: 0 .1667rem;
            }
        }
    }

    }

    span{
            display: inline-block;
            text-align: left;
            height: 48px;
            line-height: 48px;
            font-size: 12px;
            cursor: pointer;
            color: #999aaa;
            padding-left: .1333rem;
            margin: 10px 0;
        }
        span:hover{
              color:#ff3535
            }
        em{
          margin: .0833rem;
        }

}
</style>
